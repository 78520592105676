import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GlobalService } from './global.service';

@Injectable()
export class GerinusService {
    
    public controller = "crud";
    public model = "BaseModel";
    public entityName = null;
    public idField = null;
    public companyField = null;
    public companyID = null;
    public orderBy = null;
    public cnpj = null;

    constructor(
        public http: HttpClient
    ) {
   
    }

    public apiURL() {
        return environment.apiURL;
    }

    public headers(useFormData=false) {
        let entityData:any = {
            'model' : this.model,
            'entityName' : this.entityName,
            'idField' : this.idField,
            'orderBy' : this.orderBy,
        }

        let user:any = localStorage.getItem('user');
        if (user) user = JSON.parse(user);

        if (this.companyField) {
            entityData['companyField'] = this.companyField;
        }

        if (this.companyID) {
            entityData['companyID'] = this.companyID;
        }
        else if (GlobalService.companyID) {
            entityData['companyID'] = GlobalService.companyID;
        }
        else if ((user) && (user.USR_CodigoEmpresa)) {
            entityData['companyID'] = user.USR_CodigoEmpresa;
        }

        if (user) {
            entityData.Gerinus = btoa(JSON.stringify(user));
        }

        let header = {
            'Content-Type': 'application/json',
            'EntityData' : JSON.stringify(entityData)
        } 
        
        if (useFormData) {
            delete(header['Content-Type']); 
        }
        
        return {
            headers: header
        }

    }

    public getEntity(id) {
        return this.http.get(this.apiURL() + "/api/" + this.controller + "/get/" + id, this.headers());
    }

    public save(entity, useFormData=false) {
        let formData = null;
        if (useFormData) {
            formData = new FormData();
            let keys = Object.keys(entity);
            for (let key of keys) { 
                let foto = key.substring(4,8);   
                //console.log(foto);             
                if ((foto == "Foto") || (entity[key] instanceof File)) {
                    formData.append(key,entity[key]);   
                }
                else {                                                      

                    if (typeof entity[key] == 'object') {         
                        formData.append(key,JSON.stringify(entity[key]));
                    }
                    else {
                        formData.append(key,entity[key]);
                    }
                }             
            }
        }
        if (formData) {
            let id = (entity[this.idField]) ? `/${entity[this.idField]}` : '/0';
            return this.http.post(this.apiURL() + "/api/" + this.controller + "/save" + id,formData,this.headers(true));
        }
        else {
            let id = (entity[this.idField]) ? `/${entity[this.idField]}` : '/0';
            return this.http.post(this.apiURL() + "/api/" + this.controller + "/save" + id,entity,this.headers());
        }
        
    }

    public delete(id) {
        return this.http.delete(this.apiURL() + "/api/" + this.controller + "/delete/" + id, this.headers());
    }

    public listAll(condition=null,page=1) {
        let where = {}
        let pageQry = "";

        if (condition) {
            where = {
                where: condition
            }
        }
        if (page != 1) {
            pageQry = "/" + page;
        }

        return this.http.post(this.apiURL() + "/api/" + this.controller + "/get/all" + pageQry, where, this.headers());
    }
}