import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { MainService } from 'src/app/app-core/services/main.service';
import { MenuService } from 'src/app/app-core/services/menu.service';
import { MunicipioService } from 'src/app/app-core/services/municipio.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/icons', title: 'Icons', icon: 'ni-planet text-blue', class: '' },
    { path: '/maps', title: 'Maps', icon: 'ni-pin-3 text-orange', class: '' },
    { path: '/user-profile', title: 'User profile', icon: 'ni-single-02 text-yellow', class: '' },
    { path: '/tables', title: 'Tables', icon: 'ni-bullet-list-67 text-red', class: '' },
    { path: '/login', title: 'Login', icon: 'ni-key-25 text-info', class: '' },
    { path: '/register', title: 'Register', icon: 'ni-circle-08 text-pink', class: '' }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    providers: [ MenuService, MunicipioService ]
})
export class SidebarComponent implements OnInit {

    @ViewChild('content') content;

    @Input() size: string = 'custom-sm';

    private ref: NgbModalRef;

    public menuItems: any[];
    public isCollapsed = true;

    constructor(
        public global: GlobalService,
        public menu: MenuService,
        public router: Router,
        public modal: NgbModal,
        public main: MainService,
        public municipioService: MunicipioService
    ) {

    }

    ngOnInit() {
        this.menu.sideMenu().subscribe((response:any) => {
            this.menuItems = response.map(menu => this.mapToArgon(menu));
            localStorage.setItem('menu',JSON.stringify(this.menuItems));
        });
        
        this.municipioService.listAll(null, 0).subscribe((response: any) => {
            this.main.municipios = response.data;
            let municipio = this.global.municipio();
            if(!municipio) {
               municipio = this.main.municipios[0];
            } 
            this.main.municipioUsuario(municipio);
        })
    }

    public mapToArgon(menu) {
        if (menu) {
            return  {
                path: menu.url,
                title: menu.name,
                icon: menu.icon,
                children: menu.children.map(m => this.mapToArgon(m))
            }
        }
        return null;
    }

    public openModal() {
        this.ref = this.modal.open(this.content, { centered: true, size: this.size, keyboard: false, backdrop: 'static', ariaLabelledBy: 'modal-basic-title' });
        this.ref.result.then(
            (result) => {
                
            }, 
            (reason) => {
                
            }
        );
    }

    public escolheMunicipio(municipio) {
        this.main.municipioUsuario(municipio); 
        this.closeModal();
    }

    public closeModal() {
        this.ref.close();
    }
}
