import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GerinusService } from '../lib/gerinus.service';
import { GlobalService } from '../lib/global.service';

@Injectable()
export class LoginService extends GerinusService {

    constructor(
        public global: GlobalService,
        public http: HttpClient,
        public router: Router
    ) {
        super(http);
    }

    public authentication(username,password) {
        let data = {
            username: username,
            password: password
        };
        this.global.startLoading("Validando Usuário...");
        this.http.post(this.apiURL() + '/api/login',data,this.headers()).subscribe((response:any) => {            
            if (response.ok) {
                let user = response.user;                
                localStorage.setItem("user",JSON.stringify(user));
                this.router.navigate(["/dashboard"]);
                this.global.toast.success('Usuário autenticado com sucesso');
            }
            else {
                this.global.stopLoading();
                this.global.swal.fire({
                    title: "Erro!",
                    text: response.message,
                    icon: response.message_type,
                });
            }
        });

    }

    public logout() {
        localStorage.removeItem("user");
        localStorage.removeItem("municipio");
        this.router.navigate(["/login"]);
    }

}