import { Directive, ElementRef, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalService } from '../global.service';

@Directive({
    selector: '[cloudImg]'
})
export class CloudImgDirective implements OnChanges {

    @Input() src;
    @Input() backgroundImage;

    constructor(
        public el: ElementRef,
        public global: GlobalService
    ) {
        el.nativeElement.onerror = (error) => {
            el.nativeElement.src = 'assets/img/brand/no-image.png'
            console.log("[CloudImgDirective] Error",error);
        }; 
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log("[CloudImgDirective] Changes",changes);
        if (changes.src) {
            let src = this.src;
            if (src) {
                if (src.includes('http:') || src.includes('https:')) {
                    this.el.nativeElement.src = src;
                }
                else if (src.includes('/')) {
                    this.el.nativeElement.src = src;
                }
                else {
                    this.el.nativeElement.src = this.global.apiURL() + '/_fileData/' + src;
                }
            }
        }
        if (changes.backgroundImage) {
            let src = this.backgroundImage;
            if (src) {
                if (src.includes('http:') || src.includes('https:')) {
                    this.el.nativeElement.style.backgroundImage = `url(${src})`;
                }
                else if (src.includes('/')) {
                    this.el.nativeElement.style.backgroundImage = `url(${src})`;
                }
                else {
                    this.backgroundImage = this.global.apiURL() + '/_fileData/' + src;
                    this.el.nativeElement.style.backgroundImage = `url(${this.global.apiURL() + '/_fileData/' + src})`;
                }
            }
        }
    }

}
