import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class MunicipioService extends GerinusService {
 
    constructor(
        public http: HttpClient
    ) {
        super(http);
        this.entityName = "Municipio";
        this.idField = "MUN_CodigoMunicipio";
    }

    public buscaCEP(cep) {
        return this.http.get("https://viacep.com.br/ws/" + cep + "/json/");
    }
}