import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GerinusService } from '../lib/gerinus.service';

@Injectable()
export class MenuService extends GerinusService {
 
    constructor(
        public http: HttpClient
    ) {
        super(http);
        this.entityName = "Menu";
        this.idField = "MNU_CodigoMenu"; 
        this.orderBy = "MNU_Nome"
        
    }

    public sideMenu() { 
        return this.http.post(this.apiURL() + "/api/free/menu","", this.headers());     
        //return this.http.post(this.apiURL() + "/app/itens/" , {tabela:nomeTabela}, this.headers());
    } 
          
} 