import { Injectable } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';

import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class GlobalService {

    constructor (
        public toast: ToastrService
    ) {

    }

    @BlockUI() blockUI: NgBlockUI;
    public message = 'Carregando...';
    public loading = false;

    public static companyID;

    public swal = swal;
    
    public theme:any = {
        loading: false,
        logo: null,
        bgLogon: null, 
        favicon: null,
        primaryColor: '#91bd21'
    }

    public user() {
        let user:any = localStorage.getItem("user");
        if (user) {
            user = JSON.parse(user);            
        }
        return user;
    } 

    public setTheme(theme) {
        let keys = Object.keys(theme);
        for (const key of keys) {
            this.theme[key] = theme[key];
        }
        console.log("Theme",this.theme);
        const html = document.querySelector('html');
        const title = document.querySelector('title');
        const favicon = document.getElementById('favicon');
        html.style.setProperty('--primary-color', this.theme.primaryColor);
        if (this.theme.title) {
            title.text = this.theme.title;
        }
        else {
            title.text = "Gerinus Tecnologia da Informação";
        }
        if (this.theme.favicon) {
            if (this.theme.favicon.includes("/")) {
                favicon.setAttribute('href', this.theme.favicon);
            }
            else {
                favicon.setAttribute('href', this.apiURL() + '/_fileData/' + this.theme.favicon); 
            }
        }
        
    }

    public apiURL(URL=null) {
        if (URL) {
            return environment.apiURL + '/' + URL;
        }
        return environment.apiURL;
    }

    public startLoading(message=null) {
        if (message == null) {
            message = 'Carregando...';
        }
        this.blockUI.start(message);
        this.loading = true;
        this.message = message;
    }

    public stopLoading() {
        this.blockUI.stop();
        this.loading = false;
        this.message = 'Carregando...';
    }

    public loadImage(file,entity,imageField,tmpField) {
        entity[imageField]=file.target.files.item(0);    

        // FileReader support
        if (FileReader) {
            var fr = new FileReader();
            fr.onload =  () => {
                entity[tmpField] = fr.result;
            }
            fr.readAsDataURL(entity[imageField]);
        }
        // Not supported
        else {
            // fallback -- perhaps submit the input to an iframe and temporarily store
            // them on the server until the user's session ends.
        }       
    
    }

    public copyToClipboard(str) {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    public municipio() {
        let municipio:any = localStorage.getItem("municipio"); 
        if (municipio) {
            municipio = JSON.parse(municipio);
        }
        return municipio;
    }
    
}