import { Component, OnInit, ElementRef } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/app-core/lib/global.service';
import { LoginService } from 'src/app/app-core/services/login.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [ LoginService ]  
})
export class NavbarComponent implements OnInit {

    public focus;
    public listTitles: any[];
    public user;

    constructor(
        public location: Location, 
        public global: GlobalService,
        public login: LoginService,
        public element: ElementRef, 
        public router: Router
    ) {
        this.location = location;
        this.user = this.global.user();
    }

    ngOnInit() {
        //this.listTitles = ROUTES.filter(listTitle => listTitle);
        let menuTimer = setInterval(() => {
            let menu = localStorage.getItem('menu');
            if (menu) {
                this.listTitles = JSON.parse(menu);
                clearInterval(menuTimer);
            }
        },500);
    }

    getTitle(menu=null, parent = '') {
        if (menu == null) {
            menu = this.listTitles;
        }
        if (menu) {
            var titlee = this.location.prepareExternalUrl(this.location.path());
            if (titlee.charAt(0) === '#') {
                titlee = titlee.slice(1);
            }

            for (var item = 0; item < menu.length; item++) {
                if (menu[item].path === titlee) {
                    if (parent != '') parent = parent + ' / ';
                    return parent + menu[item].title;
                }
                if (menu[item].children) {
                    let child = this.getTitle(menu[item].children, menu[item].title);
                    if (child != '') {
                        if (parent != '') parent = parent + ' / ';
                        return parent + child;
                    }
                }
            }
        }
        return '';
    }

    logout() {
        this.login.logout();
    }

}
